import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export default function ButtonGroupController({
  name,
  label,
  required,
  options,
  rules,
  defaultValue,
  ...props
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || []}
      rules={{ required: required && `${label} cannot be blank.`, ...rules }}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <InputLabel>{label}</InputLabel>
          <Box pt={1.5}>
            <ButtonGroup
              className="toggle-button-group"
              variant="contained"
              aria-label="text button group"
              sx={{
                "& .MuiButtonGroup-grouped:not(:last-of-type)": {
                  borderColor: "#b3b3b3!important",
                },
              }}
            >
              {options.map((option, index) => (
                <Button
                  size="small"
                  key={index}
                  onClick={() => {
                    if (field.value.includes(option.value)) {
                      field.onChange(
                        field.value.filter((d) => d !== option.value),
                      );
                    } else {
                      field.onChange([...field.value, option.value]);
                    }
                  }}
                  className={
                    field.value.includes(option.value) ? "selected" : ""
                  }
                >
                  {option.label}
                </Button>
              ))}
            </ButtonGroup>
          </Box>
          {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
