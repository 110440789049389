import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  Button,
  Box,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import Swal from "sweetalert2";
import "./style/index.css";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import _ from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import TextFieldController from "../../components/TextFieldController";
import ButtonGroupController from "../../components/ButtonGroupController";
import ToggleButtonGroupController from "../../components/ToggleButtonGroupController";

// ************************ **********************
const dayOfWeek = [
  { label: "MON", value: 1 },
  { label: "TUE", value: 2 },
  { label: "WED", value: 3 },
  { label: "THU", value: 4 },
  { label: "FRI", value: 5 },
  { label: "SAT", value: 6 },
  { label: "SUN", value: 0 },
];

const generateTypeOption = [
  { label: "DAY OF MONTH", value: "MONTH" },
  { label: "DAY OF WEEK", value: "WEEK" },
];

const CarWashSetup = (props) => {
  // *********** VAR *************
  const { auth } = useRoleAuthorization();
  const {
    isLoading,
    sendRequest,
    httpRequestError: error,
    responseData,
  } = useHttpRequest();
  const methods = useForm({ mode: "all" });
  const { handleSubmit, control, reset, getValues, setValue, formState } =
    methods;

  // *********** FUNCTION *************

  const handleSubmitForm = async (data) => {
    try {
      await sendRequest(`/v1/car-wash-setup/setup`, "PUT", {
        ...data,
        startOfMonth: data.startOfMonth && Number(data.startOfMonth),
        daysToComplete: Number(data.daysToComplete),
        dayOfMonthlyReport: Number(data.dayOfMonthlyReport),
        daysInterval: Number(data.daysInterval),
        unitPenalty: Number(data.unitPenalty),
      });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Car Wash Setup has been updated.",
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err,
      });
    }
  };

  // *********** HOOK *************

  useEffect(() => {
    document.title = "Car Wash Setup";
    // redirect to default page if not authorized
    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.car_wash_setup,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push("/app/dashboard");
    }
    sendRequest(`/v1/car-wash-setup/setup`, "GET").then((res) => {
      reset({
        ...res.data,
      });
    });
  }, []);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  // *********** Render *************

  const pageTitleRender = (
    <Typography variant={"h1"}>Car Wash Setup</Typography>
  );

  const actionRender = auth.checkModulePrivilege(
    PrivilegeModules.car_wash_setup,
    PrivilegeActions.edit,
  ) && (
    <Button
      className={"primary"}
      type="submit"
      disabled={Object.keys(formState.errors).length !== 0}
    >
      Save
    </Button>
  );

  const generalFormRender = (
    <Box className={"form"}>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12}>
          <Typography variant={"h2"}>General</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12}>
                  <ToggleButtonGroupController
                    label={"Generate Type"}
                    name={"generateType"}
                    options={generateTypeOption}
                    defaultValue={"MONTH"}
                    required
                  />
                </Grid>
                <Controller
                  name="generateType"
                  render={({ field, fieldState: { error } }) =>
                    field.value === "WEEK" ? (
                      <Grid item xs={12}>
                        <ButtonGroupController
                          label={"Day of Week"}
                          name={"dayOfWeek"}
                          options={dayOfWeek}
                          rules={{
                            minLength: {
                              value: 1,
                              message:
                                "Day Of Monthly Report must be less than 31",
                            },
                          }}
                        />
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={12}>
                          <TextFieldController
                            name={`startOfMonth`}
                            label={`Start of Month`}
                            type={"number"}
                            rules={{
                              max: {
                                value: 31,
                                message: "Start Of Month must be less than 31",
                              },
                              min: {
                                value: 1,
                                message:
                                  "Start Of Month must be greater than 1",
                              },
                            }}
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldController
                            name={`daysInterval`}
                            label={`Day(s) Interval`}
                            type={"number"}
                            rules={{
                              max: {
                                value: 31,
                                message: "Day(s) Interval must be less than 31",
                              },
                              min: {
                                value: 1,
                                message: "Day(s) Interval must be greater than 1",
                              },
                            }}
                            required
                          />
                        </Grid>
                      </>
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12}>
                  <TextFieldController
                    name={`unitPenalty`}
                    label={`Unit Penalty`}
                    type={"number"}
                    className={"dollar"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldController
                    name={`daysToComplete`}
                    label={`Day(s) to Complete`}
                    type={"number"}
                    rules={{
                      max: {
                        value: 31,
                        message: "Day(s) to Complete must be less than 31",
                      },
                      min: {
                        value: 1,
                        message: "Day(s) to Complete must be greater than 1",
                      },
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldController
                    name={`dayOfMonthlyReport`}
                    label={`Day of Monthly Report`}
                    type={"number"}
                    rules={{
                      max: {
                        value: 31,
                        message: "Day of Monthly Report must be less than 31",
                      },
                      min: {
                        value: 1,
                        message: "Day Of Monthly Report must be greater than 1",
                      },
                    }}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  const formRender = (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {pageTitleRender}
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
            {actionRender}
          </Grid>

          <Grid item xs={12}>
            {generalFormRender}
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      {formRender}
    </>
  );
};

export default CarWashSetup;
