import { Button, Link } from "@mui/material";
import * as moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";
export const carWashHistoryTableConfig = () => [
  {
    name: "Rejected Date",
    width: "170px",
    wrap: true,
    cell: (row) => moment(row.actionDate).format("DD/MM/YYYY HH:mm:ss"),
    sortable: true,
    selector: (row) => row.actionDate,
  },
  {
    name: "Rejected By",
    width: "250px",
    selector: (row) => `${row.actionBy?.name}-${row.actionBy?.username}`,
  },
  {
    name: "Photos",
    width: "80px",
    center: true,
    selector: (row) => (
      <Button href={row.photoUrl}>
        <DownloadIcon />
      </Button>
    ),
  },
  {
    name: "Comment",
    minWidth: "350px",
    wrap: true,
    selector: (row) => row.comment,
  },
];
