import Joi from "joi";

export const companyFormValueSchema = Joi.object({
  company: Joi.object({
    _id: Joi.string(),
    status: Joi.boolean(),
    navLinkedCustomerNo: Joi.string().required().label("Customer No."),
    name: Joi.string().required().label("Name"),
    code: Joi.string().required().label("Code"),
    apiEndpoint: Joi.string().required(),
    deptstorecode: Joi.string().required(),
    salesperson: Joi.string().required(),
    gstbuspostgrp: Joi.string().required(),
  }),
  isNew: Joi.boolean().optional(),
});
