import {
  Box,
  FormControl,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormHelperText } from "@mui/material";

export default function ToggleButtonGroupController({
  name,
  label,
  required,
  options,
  rules,
  defaultValue,
  ...props
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || null}
      rules={{ required: required && `${label} cannot be blank.`, ...rules }}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <InputLabel>{label}</InputLabel>
          <Box pt={1.5}>
            <ToggleButtonGroup
              value={field.value}
              exclusive
              onChange={(event, val) => {
                if (val !== null) {
                  field.onChange(val);
                }
              }}
            >
              {options.map((option, index) => (
                <ToggleButton value={option.value} key={index}>
                  {option.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
          {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
