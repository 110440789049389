import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Webcam from "react-webcam";
import { Box, Stack } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CachedIcon from "@mui/icons-material/Cached";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function WebcamDialog(props) {
  const { isOpen, handleClose, onAddImage } = props;
  const webcamRef = React.useRef(null);
  const [image, setImage] = React.useState("");

  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);

  const onCapture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  }, [webcamRef]);

  let videoConstraints = {
    facingMode: facingMode,
  };

  const onSwitchCamera = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER,
    );
  }, []);

  const onContinue = () => {
    onAddImage(image);
    setImage("");
  };

  const onRetake = () => {
    setImage("");
  };
  return (
    <React.Fragment>
      <Dialog fullScreen open={isOpen} TransitionComponent={Transition}>
        <Box
          sx={{
            padding: "0px!important",
            height: "100vh",
            width: "100%",
            position: "relative",
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            className={"sub-action"}
            px={2}
            sx={{ width: "100%", height: "62px", background: "black" }}
          >
            <Button
              edge="start"
              type={"button"}
              onClick={handleClose}
              aria-label="close"
              size="large"
              sx={{
                margin: 0,
                background: "white",
              }}
            >
              <ArrowBackIosNewIcon color="white" />
            </Button>
          </Stack>

          {image === "" ? (
            <Webcam
              height={"100%"}
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              screenshotQuality={1}
              style={{
                width: "100%",
                height: "calc(100% - 62px - 4px)",
                objectFit: "cover",
              }}
            />
          ) : (
            <img
              src={image}
              alt="Scan"
              style={{
                height: "calc(100% - 62px - 4px)",
                width: "100vw",
                objectFit: "cover",
              }}
            />
          )}

          {image === "" ? (
            <>
              <Box
                sx={{
                  width: "84px",
                  height: "84px",
                  background: "white",
                  borderRadius: "100%",
                  position: "absolute",
                  bottom: 18,
                  left: "50%",
                  transform: "translate(-50%, 0)",
                  cursor: "pointer",
                }}
                onClick={onCapture}
              ></Box>

              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  width: "54px",
                  height: "54px",
                  background: "white",
                  borderRadius: "100%",
                  position: "absolute",
                  bottom: 28,
                  right: 18,
                  cursor: "pointer",
                }}
                onClick={onSwitchCamera}
              >
                <CachedIcon
                  sx={{
                    "&.MuiSvgIcon-root": {
                      width: "28px",
                      height: "28px",
                    },
                  }}
                />
              </Stack>
            </>
          ) : (
            <Stack>
              <Button
                size="large"
                endIcon={<ArrowRightIcon />}
                onClick={onContinue}
                sx={{
                  position: "absolute",
                  bottom: 28,
                  right: 18,
                  background: "white",
                }}
              >
                Continue
              </Button>

              <Button
                size="large"
                startIcon={<RefreshIcon />}
                onClick={onRetake}
                sx={{
                  position: "absolute",
                  bottom: 28,
                  left: 18,
                  background: "white",
                }}
              >
                Retake
              </Button>
            </Stack>
          )}
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
