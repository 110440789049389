import { IconButton, Switch, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import SaveIson from "@mui/icons-material/BookmarkBorderOutlined";
import CloseIcon from "@mui/icons-material/CloseOutlined";

export function ActionColumn({
  companyRecord,
  canCreate,
  canDelete,
  canEdit,
  deleteHandler,
  updateHandler,
  discardEditHandler,
}) {
  if (!companyRecord.isEditMode && canDelete) {
    return (
      <>
        <IconButton onClick={(e) => deleteHandler(companyRecord)}>
          <DeleteIcon />
        </IconButton>
      </>
    );
  }

  if (companyRecord.isEditMode && (canCreate || canEdit)) {
    return (
      <>
        <IconButton onClick={(e) => updateHandler(companyRecord)}>
          <SaveIson />
        </IconButton>
        <IconButton onClick={(e) => discardEditHandler(companyRecord)}>
          <CloseIcon />
        </IconButton>
      </>
    );
  }

  return <></>;
}

export const companyTableConfig = (
  permission,
  handleToggleCompanyStatus,
  handleDeleteCompany,
  handleUpdateCompany,
  rerender,
) => [
  {
    name: "Code",
    width: "80px",
    wrap: true,
    cell: (row) => {
      if (row.isEditMode) {
        return (
          <TextField
            defaultValue={row.code}
            onChange={(e) => {
              row.code = e.target.value;
            }}
          />
        );
      }
      return row.code;
    },
    sortable: true,
  },
  {
    name: "Customer No.",
    width: "100px",
    cell: (row) => {
      if (row.isEditMode) {
        return (
          <TextField
            defaultValue={row.navLinkedCustomerNo}
            onChange={(e) => {
              row.navLinkedCustomerNo = e.target.value;
            }}
          />
        );
      }
      return row?.navLinkedCustomerNo || "";
    },
    sortable: true,
  },
  {
    name: "Name",
    width: "300px",
    wrap: true,
    cell: (row) => {
      if (row.isEditMode) {
        return (
          <TextField
            defaultValue={row.name}
            onChange={(e) => {
              row.name = e.target.value;
            }}
          />
        );
      }
      return row.name;
    },
    sortable: true,
  },
  {
    name: "Department Store Code",
    width: "150px",
    wrap: true,
    cell: (row) => {
      return row?.deptstorecode;
    },
    sortable: true,
  },
  {
    name: "Salesperson",
    width: "100px",
    wrap: true,
    cell: (row) => {
      return row?.salesperson;
    },
    sortable: true,
  },
  {
    name: "GST Group",
    width: "100px",
    wrap: true,
    cell: (row) => {
      return row?.gstbuspostgrp;
    },
    sortable: true,
  },
  {
    name: "API Endpoint",
    minWidth: "300px",
    wrap: true,
    cell: (row) => {
      if (row.isEditMode) {
        return (
          <TextField
            defaultValue={row.apiEndpoint}
            onChange={(e) => {
              row.apiEndpoint = e.target.value;
            }}
          />
        );
      }
      return row.apiEndpoint;
    },
    sortable: true,
  },
  {
    name: "Enable",
    width: "80px",
    center: true,
    selector: (row) => row.status,
    cell: (row) => {
      return (
        <Switch
          checked={row.status}
          disabled={!permission.allowedEnableDisable}
          onChange={(e, value) => handleToggleCompanyStatus(row, true, value)}
          inputProps={{ "aria-label": "controlled" }}
        />
      );
    },
    sortable: true,
  },
  {
    name: "",
    width: "50px",
    cell: (row) => {
      const discardEdit = (discardRow) => {
        discardRow.isEditMode = false;
        if (discardRow.isNew) {
          discardRow.isDelete = true;
        }
        rerender();
      };

      return (
        <ActionColumn
          companyRecord={row}
          canDelete={!!permission.allowedDelete}
          canCreate={!!permission.allowedCreate}
          canEdit={!!permission.allowedCreate}
          deleteHandler={handleDeleteCompany}
          updateHandler={handleUpdateCompany}
          discardEditHandler={discardEdit}
        />
      );
    },
  },
];
