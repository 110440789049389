import { withRouter } from "react-router-dom";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  Button,
  IconButton,
} from "@mui/material";
import { useHttpRequest, useRoleAuthorization } from "../../hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import {
  CanCreateCategory,
  CanDeleteCategory,
  CanSeeCategoryList,
  CanToggleStatusCategory,
} from "./etc/permission";
import { companyTableConfig } from "./data-table/company-table-column-config";
import { Add } from "@mui/icons-material";
import { CompanyFormPopup } from "./components/company-form-popup";

const CompanyList = (props) => {
  const { isLoading, sendRequest } = useHttpRequest();
  const { sendRequest: sendDeleteRequest } = useHttpRequest();
  const { auth } = useRoleAuthorization();
  const [companyList, setCompanyList] = useState([]);
  const [companyFormValues, setCompanyFormValues] = useState();
  const [isCompanyFormOpen, setIsCompanyFormOpen] = useState(false);

  const updateCompany = useCallback(
    async (company) => {
      const request = company._id
        ? sendRequest(`/v1/company/${company._id}`, "PUT", company)
        : sendRequest(`/v1/company`, "POST", company);

      request
        .then(async (resp) => {
          if (!company?._id) {
            setCompanyList((prev) => [resp.data, ...prev]);
          } else {
            setCompanyList(
              companyList.map((companyInList) =>
                companyInList._id !== company._id ? companyInList : resp.data,
              ),
            );
          }

          await Swal.fire({
            titleText: `Company ${company.name.toUpperCase()} has been ${
              company?._id ? "updated" : "created"
            }.`,
            icon: "success",
          });
        })
        .catch((reason) => {
          if (reason.response?.data?.response?.message) {
            Swal.fire({
              icon: "warning",
              html: reason.response?.data?.response?.message?.join("<br/>"),
            });
            return;
          }
          Swal.fire({ icon: "warning", text: reason?.message });
        });
    },
    [sendRequest, companyList],
  );

  // handle edit users
  const toggleCompanyStatus = useCallback(
    async (company) => {
      company.status = !company.status;
      await updateCompany(company);
    },
    [updateCompany],
  );

  // handle delete users
  const handleDeleteUser = useCallback(
    async (company) => {
      if (company) {
        const result = await Swal.fire({
          title: `Confirmation`,
          text: `Are you sure you want to delete the company ${company.name.toUpperCase()}?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
        });

        if (result.isConfirmed) {
          const result = await sendDeleteRequest(
            `/v1/company/${company._id}`,
            "DELETE",
          );
          if (result?.status === 200 && result?.data === true) {
            setCompanyList(companyList.filter((c) => c._id !== company._id));
            await Swal.fire({
              titleText: `Company ${company.name.toUpperCase()} has been deleted.`,
              icon: "success",
            });
          }
        }
      }
    },
    [sendDeleteRequest, companyList],
  );

  const handleOpenCompanyFormPopup = async ({ company, isNew }) => {
    setCompanyFormValues({ isNew, company });
    setIsCompanyFormOpen(true);
  };

  const handleCloseCompanyFormPopup = () => {
    setIsCompanyFormOpen(false);
    setCompanyFormValues(null);
  };

  const handleCompanyFormSubmit = async (value) => {
    const { isNew, company } = value;
    await updateCompany(company);

    handleCloseCompanyFormPopup();
  };

  const addNewTableLine = useCallback(() => {
    if (companyList.find((item) => item.isNew)) {
      Swal.fire({
        icon: "info",
        text: "Please save the current record before proceeding.",
      });
      return;
    }
    setCompanyList([
      ...companyList,
      { _id: null, name: "", status: true, isEditMode: true, isNew: true },
    ]);
  }, [companyList]);

  const rerender = useCallback(() => {
    setCompanyList(companyList.filter((c) => !c.isDelete).slice());
  }, [companyList]);

  // Hook

  // set columns in data table
  const columns = useMemo(() => {
    return companyTableConfig(
      {
        allowedDelete: auth.checkModulePrivilege(...CanDeleteCategory),
        allowedEnableDisable: auth.checkModulePrivilege(
          ...CanToggleStatusCategory,
        ),
        allowedCreate: auth.checkModulePrivilege(...CanCreateCategory),
      },
      toggleCompanyStatus,
      handleDeleteUser,
      updateCompany,
      rerender,
    );
  }, [toggleCompanyStatus, handleDeleteUser, updateCompany, auth, rerender]);

  useEffect(() => {
    sendRequest("/v1/company").then((response) =>
      setCompanyList(response.data),
    );
  }, []);

  useEffect(() => {
    document.title = "Company List";

    // fetch user role list
    if (!auth.isPrivilegeDataLoaded()) {
      return;
    }

    // redirect to default page if not authorized
    !auth.checkModulePrivilege(...CanSeeCategoryList) &&
      props.history.push("/app/dashboard");
  }, []);

  // Render

  const companyFormPopupRender = (
    <CompanyFormPopup
      defaultValues={companyFormValues}
      isOpen={isCompanyFormOpen}
      onClose={handleCloseCompanyFormPopup}
      onSubmit={handleCompanyFormSubmit}
    />
  );

  //

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant={"h1"}>Company List</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
          {/* {auth.checkModulePrivilege(...CanCreateCategory) &&
          <Button className={'primary'} onClick={addNewTableLine}>New</Button>} */}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid className={"form"}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              {auth.checkModulePrivilege(...CanCreateCategory) && (
                <Grid item xs={12} className={"action"}>
                  <Button
                    className={"primary"}
                    onClick={() =>
                      handleOpenCompanyFormPopup({ company: null, isNew: true })
                    }
                  >
                    New
                  </Button>
                </Grid>
              )}
              <Grid item xs={12} className={"table"}>
                <DataTable
                  fixedHeader={true}
                  persistTableHead={true}
                  columns={columns}
                  data={companyList}
                  onRowClicked={(row) => {
                    handleOpenCompanyFormPopup({ company: row });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {companyFormPopupRender}
    </>
  );
};
export default withRouter(CompanyList);
