import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import Swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useUserState } from "../../context/UserContext";
import { KeyboardArrowDown, Clear } from "@mui/icons-material";
import { useStoreState } from "../../context/StoreContext";
import { validateText } from "../../helper/validate-textfield";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import {
  getRoleCodesFromNames,
  getRoleNamesFromCodes,
} from "../../helper/role-code-name-converter";
import { PERSONAL_CALENDAR_TYPE } from "../../data/constants";

const UserCard = (props) => {
  // handle loading & http
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest: httpRequest,
  } = useHttpRequest();

  // user info & permissions
  const { userInfo } = useUserState();

  // page authorization
  const { auth } = useRoleAuthorization();

  const [selectedUser, setSelectedUser] = useState(); // current user for edit
  const [message, setMessage] = useState(""); // handle swal message
  const [enableSwitchValue, setEnableSwitchValue] = useState(true); // enable switch => default true
  const [enabledTwoFASwitchValue, setEnabledTwoFASwitchValue] = useState(true); // enable switch => default true
  const [userRoleList, setUserRoleList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  const [roles, setRoles] = useState([]);
  const [stores, setStores] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [fnbStoreCodes, setFnbStoreCodes] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [isSetup, setIsSetup] = useState(false);
  const [userRoleListFull, setUserRoleListFull] = useState([]);

  const { userId } = useParams(); // url param => user id when editing
  const { storeList } = useStoreState();
  const [fnbStoreList, setFnbStoreList] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  const personalCalendarList = Object.values(PERSONAL_CALENDAR_TYPE);
  const [personalCalendarType, setPersonalCalendarType] = useState(null);

  // handling form when adding / editing
  const {
    register,
    handleSubmit,
    control,
    formState: { errors: formErrors },
    setError,
    getValues,
    setValue,
  } = useForm({ mode: "all", defaultValues: selectedUser });

  // set title when adding user
  useEffect(() => {
    document.title = "New - User Card";
  }, []);

  useEffect(() => {
    if (!auth.isPrivilegeDataLoaded()) {
      return;
    }

    let abortController = new AbortController();

    // redirect to default page if not authorized
    if (
      userId &&
      !auth.checkModulePrivilege(
        PrivilegeModules.user,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push("/app/dashboard");
    }

    // fetch user role list
    httpRequest("/v1/role/get-role-list", "GET", {}).then((response) => {
      setUserRoleListFull(response.data?.roles);
      setUserRoleList(response.data?.roles.map((r) => r.name) || []);

      httpRequest("/v1/user/get-fnb-store-list", "GET", {}).then(
        (resFnbStore) => {
          setFnbStoreList(resFnbStore.data);
          if (userId) {
            if (!isLoading && !responseData && userId) {
              httpRequest(`/v1/user/get-by-id/` + userId, "GET");
            }
          }
          return () => {
            abortController.abort();
            return response.data?.roles;
          };
        },
      );
    });

    loadDepartments();
    loadCompanies();
  }, []);

  // set title & form values with current user when editing
  useEffect(() => {
    if (userId && responseData?.user) {
      const user = responseData?.user;
      document.title = `${user.username}-${user.name} - User Card`;
      setSelectedUser(user);
      setValue("name", user.name || "");
      setValue("username", user.username || "");
      setValue("email", user.email || "");
      setValue("password", "");
      setValue("mobileNo", user.mobileNo);
      setValue(
        "roles",
        getRoleNamesFromCodes(user.roles, userRoleListFull) || [],
      );
      setValue("department", user.department || "");
      setValue("title", user.title || "");
      setValue("departments", user.departments || []);
      setValue("authorityLevel", user.authorityLevel || null);
      setValue("projectGroup", user.projectGroup || "");
      setValue("enable", user.status);
      setValue("enabledTwoFA", user.enabledTwoFA);
      setValue("contractValueLimit", user.contractValueLimit);
      setValue("personalCalendarType", user.personalCalendarType);
      setValue("personalGoogleCalendarId", user.personalGoogleCalendarId);
      setValue("customerNo", user.customerNo);
      setValue("team", user.team || null);
      setPersonalCalendarType(user.personalCalendarType);
      setIsSetup(user.isSetup);
      setEnableSwitchValue(user.status); // enable switch
      setEnabledTwoFASwitchValue(user.enabledTwoFA); // enable switch
      setRoles(getRoleNamesFromCodes(user.roles, userRoleListFull)); // roles multi select
      setAssociatedStoreCodes(user.associatedStoreCodes); // store code multi select
      setCompaniesSelected(user.companies); // company code multi select
      setFnbStores(user.fnbStoreCodes); // fnb store code multi select
      setDepartments(user.departments || []);
    }
  }, [responseData]);

  // show feedback message after add / edit
  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        willClose: navigateToList,
      });
    }
  }, [message, responseData]);

  // show error when error occured
  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error]);

  function loadDepartments() {
    httpRequest("/v1/department/get-list", "GET", {}).then((response) => {
      setDepartmentList(response.data?.departments?.map((d) => d.name) || []);
    });
  }

  function loadCompanies() {
    httpRequest("/v1/company?status=true", "GET", {}).then((response) => {
      setCompanyList(response.data);
    });
  }

  // form submit
  const handleSubmitForm = async (data) => {
    const user = {
      name: data.name.trim().toUpperCase(),
      username: data.username.trim().toUpperCase(),
      password: data.password,
      roles: getRoleCodesFromNames(data.roles, userRoleListFull),
      associatedStoreCodes: data.associatedStores?.map((s) => s.code),
      companies: data.companies?.map((s) => s.code),
      department: data.department.trim().toUpperCase(),
      departments: data.departments || [],
      authorityLevel: data.authorityLevel ?? undefined,
      projectGroup: data.projectGroup.trim().toUpperCase(),
      status: data.enable,
      title: data.title,
      enabledTwoFA: data.enabledTwoFA,
      mobileNo: data.mobileNo,
      email: data.email?.trim(),
      isSetup: isSetup,
      contractValueLimit: Number(data.contractValueLimit || 0),
      personalCalendarType: personalCalendarType,
      personalGoogleCalendarId:
        personalCalendarType != PERSONAL_CALENDAR_TYPE.GOOGLE
          ? ""
          : data.personalGoogleCalendarId,
      fnbStoreCodes: data.fnbStoreCodes?.map((s) => s.storeCode),
      customerNo: data.customerNo,
      team: data.team,
    };

    let result = null;
    // check whether add or edit attempt
    if (selectedUser && selectedUser._id) {
      user._id = selectedUser._id; // set _id before edit
      result = await httpRequest(`/v1/user/${user._id}`, "PUT", user);
      if (result?.status === 200 && result?.data?.user) {
        setMessage(`User ${user.username}-${user.name} has been updated.`); // call show feedback message
      }
    } else {
      result = await httpRequest("/v1/user/signup", "POST", user);
      if (result?.status === 201 && result?.data?.user) {
        setMessage(`New user ${user.username}-${user.name} has been created.`); // call show feedback message
      }
    }
  };

  const resetTwoFA = async () => {
    let result = await httpRequest(
      `/v1/user/reset-two-fa/${selectedUser._id}`,
      "PATCH",
    );
    if (result?.status === 200) {
      setIsSetup(false);
      setMessage(result.data.message);
    }
  };

  // navigate back to material list
  const navigateToList = () => {
    if (!userId) window.location.href = "#/app/user/list";
  };

  // validate roles multi select when touched
  const onBlurRoles = () => {
    if (!getValues("roles"))
      setError("roles", { message: "Roles cannot be blank." });
  };

  // set selected stores
  const setAssociatedStoreCodes = (data) => {
    const stores = storeList.filter((s) => data.includes(s.code));
    setValue("associatedStores", stores || []);
    setStores(stores);
  };

  // set selected stores
  const setCompaniesSelected = (data) => {
    const companies = companyList.filter((s) => data.includes(s.code));
    setValue("companies", companies || []);
    setCompanies(companies);
  };

  // set selected stores
  const setFnbStores = (data) => {
    const stores = fnbStoreList.filter((s) => data.includes(s.storeCode));
    setValue("fnbStoreCodes", stores || []);
    setFnbStoreCodes(stores);
  };

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      {(!userId || (userId && selectedUser)) && (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant={"h1"}>
                User Card{" "}
                {selectedUser
                  ? ` - ${selectedUser.username}-${selectedUser.name}`
                  : ` - New`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
              <Button onClick={() => props.history.push("/app/user/list")}>
                Cancel
              </Button>

              {((!userId &&
                auth.checkModulePrivilege(
                  PrivilegeModules.user,
                  PrivilegeActions.add,
                )) ||
                (userId &&
                  auth.checkModulePrivilege(
                    PrivilegeModules.user,
                    PrivilegeActions.edit,
                  ))) && (
                <>
                  {auth.checkModulePrivilege(
                    PrivilegeModules.user,
                    PrivilegeActions.reset_2fa,
                  ) &&
                    enabledTwoFASwitchValue &&
                    selectedUser &&
                    selectedUser._id &&
                    isSetup && (
                      <>
                        <Button className={"primary"} onClick={resetTwoFA}>
                          Reset 2FA
                        </Button>
                      </>
                    )}

                  <Button className={"primary"} type={"submit"}>
                    Save
                  </Button>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container className={"form"}>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <Typography variant={"h2"}>General</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"name"}
                            label={"Name"}
                            variant={"outlined"}
                            {...register("name", {
                              required: {
                                value: true,
                                message: "Name cannot be blank.",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.name}
                            helperText={formErrors?.name?.message}
                            onChange={(event) => validateText(event)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"department"}
                            label={"Department Code"}
                            autoComplete={"off"}
                            variant={"outlined"}
                            {...register("department")}
                            error={!!formErrors?.department}
                            helperText={formErrors?.department?.message}
                            onChange={(event) => validateText(event)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"username"}
                            label={"Username"}
                            variant={"outlined"}
                            {...register("username", {
                              required: {
                                value: true,
                                message: "Username cannot be blank.",
                              },
                            })}
                            autoComplete={"new-password"}
                            error={!!formErrors?.username}
                            helperText={formErrors?.username?.message}
                            onChange={(event) => validateText(event)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"password"}
                            label={"Password"}
                            variant={"outlined"}
                            {...register("password")}
                            type={"password"}
                            autoComplete={"new-password"}
                            error={!!formErrors?.password}
                            helperText={formErrors?.password?.message}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Controller
                            name={`roles`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: "Roles cannot be blank.",
                              },
                            }}
                            render={(props) => (
                              <Autocomplete
                                id={"user-role"}
                                multiple
                                options={userRoleList}
                                getOptionLabel={(role) => role}
                                onBlur={(event) => onBlurRoles()}
                                value={roles}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={"Roles"}
                                    variant={"outlined"}
                                    error={!!props?.formState?.errors?.roles}
                                    helperText={
                                      props?.formState?.errors?.roles?.message
                                    }
                                    autoComplete="off"
                                  />
                                )}
                                onChange={(event, data) => {
                                  setRoles(data);
                                  props?.field.onChange(data);
                                }}
                                popupIcon={<KeyboardArrowDown />}
                                ChipProps={{ deleteIcon: <Clear /> }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Controller
                            name={`associatedStores`}
                            control={control}
                            render={(props) => (
                              <Autocomplete
                                id={"associated-stores"}
                                multiple
                                options={storeList}
                                getOptionLabel={(store) => `${store.name}-${store.code}`}
                                value={stores}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={"Location"}
                                    variant={"outlined"}
                                    autoComplete="off"
                                  />
                                )}
                                onChange={(event, data) => {
                                  setStores(data);
                                  props?.field.onChange(data);
                                }}
                                popupIcon={<KeyboardArrowDown />}
                                ChipProps={{ deleteIcon: <Clear /> }}
                              />
                            )}
                          />
                        </Grid>

                        {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <Controller
                                                name={`departments`}
                                                control={control}
                                                render={(props) => (
                                                    <Autocomplete
                                                        id={"departments"}
                                                        multiple
                                                        options={departmentList}
                                                        getOptionLabel={(department) => department}
                                                        value={departments}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                label={"Departments"}
                                                                variant={"outlined"}
                                                                autoComplete="off"
                                                            />
                                                        }
                                                        onChange={(event, data) => {
                                                            setDepartments(data);
                                                            props?.field.onChange(data);
                                                        }}
                                                        popupIcon={<KeyboardArrowDown />}
                                                        ChipProps={{ deleteIcon: <Clear /> }}
                                                    />
                                                )}
                                            />
                                        </Grid> */}
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Controller
                            name={`fnbStoreCodes`}
                            control={control}
                            render={(props) => (
                              <Autocomplete
                                id={"fnb-store-codes"}
                                multiple
                                options={fnbStoreList}
                                getOptionLabel={(store) => `${store.name}-${store.storeCode}`}
                                value={fnbStoreCodes}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={"F&B Store"}
                                    variant={"outlined"}
                                    autoComplete="off"
                                  />
                                )}
                                onChange={(event, data) => {
                                  setFnbStoreCodes(data);
                                  props?.field.onChange(data);
                                }}
                                popupIcon={<KeyboardArrowDown />}
                                ChipProps={{ deleteIcon: <Clear /> }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Controller
                            name={`companies`}
                            control={control}
                            render={(props) => (
                              <Autocomplete
                                id={"companies"}
                                multiple
                                options={companyList}
                                getOptionLabel={(company) => `${company.name}-${company.code}`}
                                value={companies}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={"Company"}
                                    variant={"outlined"}
                                    autoComplete="off"
                                  />
                                )}
                                onChange={(event, data) => {
                                  setCompanies(data);
                                  props?.field.onChange(data);
                                }}
                                popupIcon={<KeyboardArrowDown />}
                                ChipProps={{ deleteIcon: <Clear /> }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"title"}
                            label={"Title"}
                            autoComplete={"off"}
                            variant={"outlined"}
                            {...register("title")}
                            error={!!formErrors?.title}
                            helperText={formErrors?.title?.message}
                            onChange={(event) => validateText(event)}
                          />
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                            <TextField id={"authority-level"} label={"Authority Level"}
                                                variant={"outlined"}
                                                {...register("authorityLevel")}
                                                autoComplete={"off"}
                                                type={"number"}
                                            />
                                        </Grid> */}
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"project-group"}
                            label={"Project Group"}
                            variant={"outlined"}
                            {...register("projectGroup")}
                            autoComplete={"off"}
                            helperText={formErrors?.projectGroup?.message}
                            onChange={(event) => validateText(event)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"mobileNo"}
                            label={"Mobile No."}
                            variant={"outlined"}
                            {...register("mobileNo")}
                            type={"text"}
                            error={!!formErrors?.mobileNo}
                            helperText={formErrors?.mobileNo?.message}
                            inputProps={{ minLength: 8, maxLength: 8 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"email"}
                            label={"Email"}
                            variant={"outlined"}
                            {...register("email", {
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email format.",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.email}
                            helperText={formErrors?.email?.message}
                            onChange={(event) => validateText(event)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"contract-value-limit"}
                            label={"Contract Value Limit"}
                            variant={"outlined"}
                            {...register("contractValueLimit", {
                              pattern: {
                                value: /^\d*\.?\d*$/,
                                message:
                                  "Contract Value Limit may contain only numbers (0-9).",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.contractValueLimit}
                            helperText={formErrors?.contractValueLimit?.message}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <Autocomplete
                            id={"personalCalendarType"}
                            options={personalCalendarList}
                            getOptionLabel={(option) => option}
                            value={personalCalendarType}
                            onChange={(e, newValue) =>
                              setPersonalCalendarType(newValue)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Personal Calendar"}
                                variant={"outlined"}
                              />
                            )}
                            popupIcon={<KeyboardArrowDown />}
                          />
                        </Grid>

                        {personalCalendarType ==
                          PERSONAL_CALENDAR_TYPE.GOOGLE && (
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <TextField
                              id={"personalGoogleCalendarId"}
                              label={"Personal Google Calendar ID"}
                              variant={"outlined"}
                              {...register("personalGoogleCalendarId")}
                              error={!!formErrors?.personalGoogleCalendarId}
                              helperText={
                                formErrors?.personalGoogleCalendarId?.message
                              }
                              onChange={(event) => validateText(event)}
                            />
                          </Grid>
                        )}

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"customerNo"}
                            label={"Customer No."}
                            autoComplete={"off"}
                            variant={"outlined"}
                            {...register("customerNo")}
                            error={!!formErrors?.customerNo}
                            helperText={formErrors?.customerNo?.message}
                            onChange={(event) => validateText(event)}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"team"}
                            label={"Team"}
                            autoComplete={"off"}
                            variant={"outlined"}
                            {...register("team")}
                            error={!!formErrors?.team}
                            helperText={formErrors?.team?.message}
                            onChange={(event) => validateText(event)}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            variant={"subtitle"}
                            labelPlacement="start"
                            control={
                              <Switch
                                {...register("enable")}
                                checked={enableSwitchValue}
                                onChange={(e) =>
                                  setEnableSwitchValue(e.target.checked)
                                }
                              />
                            }
                            label="Enable"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            variant={"subtitle"}
                            labelPlacement="start"
                            control={
                              <Switch
                                {...register("enabledTwoFA")}
                                checked={enabledTwoFASwitchValue}
                                onChange={(e) =>
                                  setEnabledTwoFASwitchValue(e.target.checked)
                                }
                              />
                            }
                            label="2FA"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

export default withRouter(UserCard);
